import React from 'react';
import ContactForm from '../components/ContactForm';

function Contact() {
    return (
        <div className="contact">
            <ContactForm />
        </div>
    );
}

export default Contact;
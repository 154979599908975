import HomeInfo from '../components/HomeInfo'

function Home () {
return (
   <main>
    <HomeInfo/>
    </main>
   
)
}


export default Home